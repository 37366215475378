<template>
  <div>
    <v-card class="sales-scroll-card">
        <marquee behavior="" direction="" style="font-size:14px">
            Invoice: 200100088, Customer: C00100 - Test 2, Amount: 5300.00, Paid: 0.00, Due: 5300.00 | Invoice: 200100087, Customer: C00100 - Test 2, Amount: 7500.00, Paid: 0.00, Due: 7500.00 | Invoice: 200100086, Customer: C00100 - Test 2, Amount: 2200.00, Paid: 0.00, Due: 2200.00 | Invoice: 200100085, Customer: C00003 - Al Modin Enterprise, Amount: 210000.00, Paid: 200000.00, Due: 10000.00 | Invoice: 200100084, Customer: C00100 - Test 2, Amount: 930.00, Paid: 0.00, Due: 930.00 | Invoice: 200100083, Customer: C00100 - Test 2, Amount: 609.00, Paid: 0.00, Due: 609.00 | Invoice: 200100082, Customer: C00100 - Test 2, Amount: 220.00, Paid: 0.00, Due: 220.00 | Invoice: 200100081, Customer: C00062 - Test 2, Amount: 35.00, Paid: 0.00, Due: 35.00 | Invoice: 200100080, Customer: C00017 - Md. Mozammel Hossain, Amount: 310.00, Paid: 0.00, Due: 310.00 | Invoice: 200100079, Customer: C00003 - Al Modin Enterprise, Amount: 655.00, Paid: 655.00, Due: 0.00 | Invoice: 200100078, Customer: C00017 - Md. Mozammel Hossain, Amount: 6200.00, Paid: 6200.00, Due: 0.00 | Invoice: 200100076, Customer: C00007 - Hashemi, Amount: 630.00, Paid: 457.50, Due: 172.50 | Invoice: 200100075, Customer: C00007 - Hashemi, Amount: 172.50, Paid: 172.50, Due: 0.00 | Invoice: 200100074, Customer: C00017 - Md. Mozammel Hossain, Amount: 2825.00, Paid: 0.00, Due: 2825.00 | Invoice: 200100073, Customer: C00006 - jubaer, Amount: 1550.00, Paid: 1550.00, Due: 0.00 | Invoice: 200100072, Customer: C00017 - Md. Mozammel Hossain, Amount: 1500.00, Paid: 1500.00, Due: 0.00 | Invoice: 200100071, Customer: C00017 - Md. Mozammel Hossain, Amount: 60.00, Paid: 60.00, Due: 0.00 | Invoice: 200100070, Customer: C00023 - , Amount: 60000.00, Paid: 60000.00, Due: 0.00 | Invoice: 200100069, Customer: C00017 - Md. Mozammel Hossain, Amount: 1290.00, Paid: 1290.00, Due: 0.00 | Invoice: 200100068, Customer: C00017 - Md. Mozammel Hossain, Amount: 4630.00, Paid: 4600.00, Due: 30.00
        </marquee>
    </v-card>
    
    <v-row no-gutters>
        <v-col cols="12" md="2">
            <v-card class="widget widget-1">
                <div class="widget-icon">
                    <v-icon>mdi-cart-outline</v-icon>
                </div>
                <div class="widget-text">Today's Collection</div>
                <div class="widget-value">Tk. {{ $store.getters['testReceipt/todayCollection'].total }}</div>
            </v-card>
        </v-col>
        <v-col cols="12" md="2">
            <v-card class="widget widget-2">
                <div class="widget-icon">
                    <v-icon>mdi-currency-bdt</v-icon>
                </div>
                <div class="widget-text">Collection</div>
                <div class="widget-value">Tk. 0.00</div>
            </v-card>
        </v-col>
        <v-col cols="12" md="2">
            <v-card class="widget widget-3">
                <div class="widget-icon">
                    <v-icon>mdi-cart-outline</v-icon>
                </div>
                <div class="widget-text">Monthly Collection</div>
                <div class="widget-value">Tk. {{ $store.getters['testReceipt/lastMonthCollection'].total }}</div>
            </v-card>
        </v-col>
        <v-col cols="12" md="2">
            <v-card class="widget widget-4">
                <div class="widget-icon">
                    <v-icon>mdi-reply-outline</v-icon>
                </div>
                <div class="widget-text">Total Due</div>
                <div class="widget-value">Tk. {{ $store.getters['patient/patientDue'] }}</div>
            </v-card>
        </v-col>
        <v-col cols="12" md="2">
            <v-card class="widget widget-5">
                <div class="widget-icon">
                    <v-icon>mdi-currency-usd</v-icon>
                </div>
                <div class="widget-text">Cash Balance</div>
                <div class="widget-value">Tk. {{ $store.getters['cashTransaction/totalCashBalance'] }}</div>
            </v-card>
        </v-col>
        <v-col cols="12" md="2">
            <v-card class="widget widget-6">
                <div class="widget-icon">
                    <v-icon>mdi-currency-usd</v-icon>
                </div>
                <div class="widget-text">Bank Balance</div>
                <div class="widget-value">Tk. {{ $store.getters['bankTransaction/totalBankBalance'] }}</div>
            </v-card>
        </v-col>
    </v-row>

    <v-row class="mt-4">
        <v-col cols="12" v-if="salesGraph == 'monthly'">
            <h3 class="text-center">This Month's Sale</h3>
            <GChart
                type="ColumnChart"
                :data="monthData"
                :options="chartOptions"
            ></GChart>
        </v-col>
        <v-col cols="12" v-if="salesGraph == 'yearly'">
            <h3 class="text-center">This Year's Sale</h3>
            <GChart
                type="ColumnChart"
                :data="yearData"
                :options="chartOptions"
            ></GChart>
        </v-col>
        <v-col cols="12" class="text-center pt-0">
            <v-btn @click="salesGraph = 'monthly'" depressed color="indigo" dark class="mr-2">Monthly</v-btn>
            <v-btn @click="salesGraph = 'yearly'" depressed color="purple" dark>Yearly</v-btn>
        </v-col>
    </v-row>

  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'

export default {
    name: 'Business-Monitor',

    components: {
        GChart
    },

    data () {
        return {
            salesGraph: 'monthly',
            monthData: [
                ['Year', 'Sales', 'Expenses', 'Profit'],
                ['2014', 1000, 400, 200],
                ['2015', 1170, 460, 250],
                ['2016', 660, 1120, 300],
                ['2017', 1030, 540, 350]
            ],
            yearData: [
                ['Year', 'Sales', 'Expenses', 'Profit'],
                ['2018', 9453, 563, 865],
                ['2019', 3454, 786, 453],
                ['2020', 5678, 345, 784],
                ['2021', 8974, 234, 236]
            ],
            chartOptions: {
                chart: {
                    title: 'Company Performance',
                    subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                }
            },
        }
    },

    created() {
        this.$store.dispatch('cashTransaction/getTotalCashBalance');
        this.$store.dispatch('bankTransaction/getTotalBankBalance');
        this.$store.dispatch('patient/getPatientTotalDue');
        this.$store.dispatch('testReceipt/getTodayCollection');
        this.$store.dispatch('testReceipt/getLastMonthCollection');
    },
}
</script>

<style lang="scss" scoped>
.sales-scroll-card {
    border-radius: unset !important;
    background: #263238 !important;
    color: #fff !important;
    padding: 4px 10px 3px;
    margin-top: 10px;
}
.widget {
    margin: 15px 10px;
    text-align: center;

    .widget-icon {
        width: 40px;
        height: 40px;
        border-radius: 50% !important;
        margin: auto;
        padding-top: 8px;
        margin-top: 8px;
        margin-bottom: 3px;
        
        .v-icon {
            color: #fff;
        }
    }

    .widget-text {
        font-size: 13px;
        font-weight: bold;
        color: rgb(94, 94, 94);
        letter-spacing: .5px;
    }

    .widget-value {
        font-weight: bold;
        color: rgb(53, 53, 53);
    }
}

.widget-1 {
    border-top: 5px solid #1C8DFF !important;
    .widget-icon { background: #1C8DFF }
}
.widget-2 {
    border-top: 5px solid #666633 !important;
    .widget-icon { background: #666633 }
}
.widget-3 {
    border-top: 5px solid #008241 !important;
    .widget-icon { background: #008241 }
}
.widget-4 {
    border-top: 5px solid #FF8000 !important;
    .widget-icon { background: #FF8000 }
}
.widget-5 {
    border-top: 5px solid #AE0000 !important;
    .widget-icon { background: #AE0000 }
}
.widget-6 {
    border-top: 5px solid #663300 !important;
    .widget-icon { background: #663300 }
}
</style>